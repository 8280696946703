:root{
	font-size: 12px;
	@media (min-width: 1000px) and (max-width: 1023px) {
			font-size: 8.3px;
		}
	@media (min-width: 1024px) and (max-width: 1199px) {
			font-size: 8.5px;
		}	
	@media (min-width: 1200px) and (max-width: 1439px) {
		font-size: 10px;
	}
	@media (min-width: 1440px) and (max-width: 1679px) {
		font-size: 12px;
	}
	@media (min-width: 1680px) and (max-width: 3020px) {
		font-size: 14px;
	}


	@media (min-width: 320px) and (max-width: 374px) {
		font-size: 9.1px;
	}
	@media (min-width: 375px) and (max-width: 424px) {
		font-size: 10.76px;
	}
	@media (min-width: 425px) and (max-width: 514px) {
		font-size: 12.2px;
	}
	@media (min-width: 515px) and (max-width: 649px) {
		font-size: 14.7px;
	}
	@media (min-width: 650px) and (max-width: 999px) {
		font-size: 18.6px;
	}
}
*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

body{
	overflow-x: hidden;
	.hide{
		display: none;
	}
}
ul, li{
	list-style: none;
	

}
button{
	outline: none;
	border: none;
	background-color: none;
}
a{
	text-decoration: none;
	color: black;
}

h1{
	font-size: 6.6rem;
	font-weight: 600;
	line-height: 8rem;
	color: #161718;
}

p{
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2rem;
	color:#676B72 ;
}